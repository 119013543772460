<template>
  <div>
    <b-container>
      <b-row no-gutters class="justify-content-center align-items-center">
        <b-col cols="12" class="mb-4 mt-3">
          <h1 class="font-weight-bold">Privacy Policy</h1>
          <p>This privacy policy is for the GANA</p>
          <p>
            We do not share your private information with other parties. Your confidentiality is key to us and is
            according to YOUR DATA PROTECTION RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION (GDPR)
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">INFORMATION WE COLLECT</h2>
          <p>We collect the following types of information. Information you provide us directly:</p>
          <ul>
            <li>
              <p>
                When you register for an account:
              </p>
              <ul>
                <li>
                  <p>
                    Via Email: Your Full Name, password, and email address.
                  </p>
                </li>
                <li>
                  <p>
                    Profile information that you provide for your user profile.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Communications between you and GANA. For example, we may send you Service-related emails (e.g., account
                verification, changes/updates to features of the Service, technical and security notices). Note that you
                may not opt out of Service-related e-mails.
              </p>
            </li>
            <li>
              <p>
                Your email and password are private and not available to the public.
              </p>
            </li>
          </ul>
          <p>Technical information we collect:</p>
          <ul>
            <li>
              <p>
                Additionally, any use of GANA's website and/or software may result in the collection of technical
                information such as your computer’s IP address, mobile device identifier, meta data, operating system,
                browser name/version, the referring web page, requested page; such information is used to help us
                understand the overall usage pattern of our website and software.
              </p>
            </li>
            <li>
              <p>
                We also use third-party analytics tools to help us measure how you use GANA. These tools collect
                information sent by your device or our Service, including how you like to use our software and website
                which can help us in improving our Service.
              </p>
            </li>
            <li>
              <p>
                GANA may invite you to provide information through feedback on features on our website or software; we
                may send out e-mail inviting feedback, or we may ask you for information concerning support of our
                services. Your response to these communications may require you to submit personal information and
                opinions.
              </p>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">HOW WE USE YOUR INFORMATION</h2>
          <p>We may use the information that we receive to:</p>
          <ul>
            <li>
              <p>
                Help you efficiently access your information after you sign in
              </p>
            </li>
            <li>
              <p>
                Remember information so you will not have to re-enter it during your visit or the next time you visit
                the Service;
              </p>
            </li>
            <li>
              <p>
                Provide, improve, test, and monitor the effectiveness of our Service
              </p>
            </li>
            <li>
              <p>
                Develop and test new products and features
              </p>
            </li>
            <li>
              <p>
                Monitor metrics such as total number of visitors, traffic, and demographic patterns
              </p>
            </li>
            <li>
              <p>
                Diagnose or fix technology problems
              </p>
            </li>
            <li>
              <p>
                Automatically update the GANA website
              </p>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">SHARING OF YOUR INFORMATION</h2>
          <p>
            We will not rent or sell your information to third parties outside GANA without your consent, except as
            noted in this Policy. Parties with whom we may share your information:
          </p>
          <ul>
            <li>
              <p>
                We may remove parts of data that can identify you and share anonymized data with other parties. We may
                also combine your information with other information in a way that it is no longer associated with you
                and share that aggregated information.
              </p>
            </li>
            <li>
              <p>
                We may be required to disclose your information without your prior consent by law for the following
                reasons:
              </p>
              <ul>
                <li>
                  <p>
                    Comply with the law or legal process
                  </p>
                </li>
                <li>
                  <p>
                    Protect and defend our rights and property, or the rights and property of a third party
                  </p>
                </li>
                <li>
                  <p>
                    Protect us against misuse or unauthorized use of any of GANA's products, software, services, or
                    other proprietary materials
                  </p>
                </li>
                <li>
                  <p>
                    Prevent death or imminent bodily harm. Prevent and address fraud and other illegal activity
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">HOW WE STORE YOUR INFORMATION</h2>
          <p>
            Storage and Processing:
          </p>
          <ul>
            <li>
              <p>
                We use commercially reasonable precautions to help keep your personal information safe and stored
                securely. However, GANA cannot ensure the security of any information you transmit to GANA or guarantee
                that information on the Service may not be accessed, disclosed, altered, or destroyed.
              </p>
            </li>
            <li>
              <p>
                Please do your part to help us. You are responsible for maintaining the secrecy of your unique password
                and account information, and for controlling access to emails between you and GANA, at all times. Your
                privacy settings may also be affected by changes the social media services you connect to GANA make to
                their services. We are not responsible for the functionality, privacy, or security measures of any other
                organization.
              </p>
            </li>
            <li>
              <p>
                Following termination or deactivation of your account, GANA, its Affiliates, or its Service Providers
                may retain information (including your profile information) and User Content for a commercially
                reasonable time for backup, archival, and/or audit purposes.
              </p>
            </li>
            <li>
              <p>
                Email our support to know about deleting your account
                <a href="mailto:contact@getgana.com" title="send an email to contact@getgana.com">support@GANA.me</a>.
              </p>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">CHILDREN’S PRIVACY</h2>
          <p>
            GANA does not knowingly collect or solicit any information from anyone under the age of 13 or knowingly
            allow such persons to register for the Service. The Service and its content are not directed at children
            under the age of 13. In the event that we learn that we have collected personal information from a child
            under age 13 without parental consent, we will delete that information as quickly as possible. If you
            believe that we might have any information from or about a child under 13, please contact us.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">OTHER WEBSITES AND SERVICES</h2>
          <p>
            We are not responsible for the practices employed by any websites or services linked to or from our Service,
            including the information or content contained within them. Please remember that when you use a link to go
            from our Service to another website or service, our Privacy Policy does not apply to those third-party
            websites or services. Your browsing and interaction on any third-party website or service, including those
            that have a link on our website/software, or are accessed through our web/in-app browser are subject to that
            third party’s own rules and policies. In addition, you agree that we are not responsible and do not have
            control over any third-parties that you authorize to access your User Content. If you are using a
            third-party website or service and you allow them to access your User Content you do so at your own risk.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">
            YOUR DATA PROTECTION RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION (GDPR)
          </h2>
          <p>
            If you are a resident of the EEA, you have the following data protection rights:
          </p>
          <ul>
            <li>
              <p>
                f you wish to access, correct, update, or request deletion of your personal information, you can do so
                at any time by contacting
                <a href="mailto:contact@getgana.com" title="send an email to contact@getgana.com">support@GANA.me</a>.
              </p>
            </li>
            <li>
              <p>
                In addition, you can object to the processing of your personal information, ask us to restrict the
                processing of your personal information or request portability of your personal information. Again, you
                can exercise these rights by emailing
                <a href="mailto:contact@getgana.com" title="send an email to contact@getgana.com">support@GANA.me</a>.
              </p>
            </li>
            <li>
              <p>
                You have the right to opt-out of marketing communications we send you at any time. You can exercise this
                right by clicking on the “unsubscribe” or “opt-out” link in the marketing emails we send you. To opt-out
                of other forms of marketing, please contact us by emailing
                <a href="mailto:contact@getgana.com" title="send an email to contact@getgana.com">support@GANA.me</a>.
              </p>
            </li>
            <li>
              <p>
                Similarly, if we have collected and process your personal information with your consent, then you can
                withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any
                processing we conducted prior to your withdrawal, nor will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds other than consent.
              </p>
            </li>
            <li>
              <p>
                You have the right to complain to a data protection authority about our collection and use of your
                personal information. For more information, please contact your local data protection authority.
              </p>
            </li>
          </ul>
          <p>
            We respond to all requests we receive from individuals wishing to exercise their data protection rights in
            accordance with applicable data protection laws.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">
            OTHER WEBSITES AND SERVICES
          </h2>
          <p>
            If you have any questions about this Privacy Policy or the Service, please find the appropriate support
            channel in the GANA settings or contact us at
            <a href="mailto:contact@getgana.com" title="send an email to contact@getgana.com">support@GANA.me</a>.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">
            CHANGES TO OUR PRIVACY POLICY
          </h2>
          <p>
            GANA may modify or update this Privacy Policy from time to time, so please review it periodically. We may
            provide you additional forms of notice of modifications or updates as appropriate under the circumstances.
            Your continued use of GANA or the Service after any modification to this Privacy Policy will constitute your
            acceptance of such modification.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
