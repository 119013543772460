<template>
  <div class="">
    <navBarUnauth></navBarUnauth>
    <router-view />
    <contact-us class="contact_us_button"></contact-us>
    <app-footer v-if="$route.name == 'about_us'" class="w-100 p-0"></app-footer>
    <!-- Toast to show if action succeeded or failed -->
    <transition name="fade-down">
      <toasts v-if="showToast" class="toasts"></toasts>
    </transition>
  </div>
</template>

<script>
// Import toasts from store
import { TOASTS_SET } from '@/store/modules/toasts/mutation-types.js';
import { mapState, mapGetters } from 'vuex';
export default {
  name: '',
  components: {
    navBarUnauth: () => import('@/components/nav_bar_unauth/nav_bar_unauth.vue'),
    contactUs: () => import('@/components/contact_us/contact_us.vue'),
    toasts: () => import('@/components/reusables/toasts.vue'),
    appFooter: () => import('@/components/footer/footer.vue'),
  },
  computed: {
    ...mapState({
      showToast: (state) => state.toasts.showToast,
    }),
  },
  watch: {
    $route() {
      if (this.$route.name == 'about_us') {
        document.body.style = 'background: white';
      } else {
        document.body.style = 'background: #f3f2f7';
      }
    },
  },
  data() {
    return this.initial_state();
  },
  mounted() {
    if (this.$route.name == 'about_us') {
      document.body.style = 'background: white';
    } else {
      document.body.style = 'background: #f3f2f7';
    }

    const vh = window.innerHeight * 0.01;

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  methods: {
    initial_state() {
      return {};
    },
  },
};
</script>

<style>
html,
body {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

button:focus {
  outline: none;
}

.underline {
  text-decoration: underline;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Animation for toast to slide in and out */
.fade-down-enter-active {
  animation: fade-down 0.5s;
}
.fade-down-leave-active {
  animation: fade-down 0.5s reverse;
}
@keyframes fade-down {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
