import { mapState, mapGetters } from 'vuex';

import { ApiClassesGet } from '@//apiDocument/classes/index.js';

import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

const moment = require('moment');
const moment_tz = require('moment-timezone');

export default {
  name: 'home',
  data() {
    return this.initial_state();
  },
  components: {
    popper: Popper,
    classCard: () => import('@/components/reusables/class_card.vue'),
  },
  computed: {
    ...mapGetters({
      get_class_type: 'get_class_type',
    }),
    filterClasses() {
      return this.filterByType(this.filterByDuration(this.classes));
    },
    getToday() {
      return moment(this.selectedDate).format('D MMMM YYYY');
    },
    filterOverlay() {
      return {
        height: window.innerHeight - 70 + 'px',
        width: '100%',
      };
    },
    isLargeScreen() {
      if (screen.width >= 768) return true;
      return false;
    },
  },
  watch: {
    selectedDate() {
      this.get_class();
    },
    classType() {
      this.check_filtered();
    },
    minTime() {
      this.check_filtered();
    },
    maxTime() {
      this.check_filtered();
    },
  },
  methods: {
    initial_state() {
      return {
        // all classes
        classes: [],

        // one class when one presses more
        moreModalClass: null,

        // loading
        loading: false,

        // filters
        showFilterOverlay: false,
        isFiltered: false,
        // class type
        classType: 'all',
        // class length
        minTime: 10,
        maxTime: 90,

        slider: null,

        // selected date
        selectedDate: moment().format('YYYY-MM-DD'),
        // popper
        popperOptions: {
          placement: 'left',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [100, 0],
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom'],
              },
            },
          ],
        },
      };
    },
    /** *
     * check filtered
     */
    check_filtered() {
      if (this.classType != 'all' || this.minTime != 10 || this.maxTime != 10) {
        this.isFiltered = true;
      } else {
        this.isFiltered = false;
      }
    },
    /** *
     * filter by class type
     */
    filterByType(classes) {
      return classes.filter((item) => {
        if (
          item.fields.classType.toLowerCase() == this.classType.toLowerCase() ||
          this.classType.toLowerCase() == 'all'
        ) {
          return item;
        }
      });
    },
    /** *
     * filter by class duration
     */
    filterByDuration(classes) {
      return classes.filter((item) => {
        if (item.fields.duration / 60 >= this.minTime && item.fields.duration / 60 <= this.maxTime) {
          return item;
        }
      });
    },
    /** *
     * Add subtract class date
     */
    get_class_date(time) {
      if (!time) {
        this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD')
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
      } else {
        this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD');
      }
    },
    /** *
     * Check if current date
     */
    check_class_time(date, index) {
      if (index != 0) {
        if (moment(date).format() == moment(this.filterClasses[index - 1].fields.date).format()) {
          return false;
        }
        return true;
      }
      return true;
    },
    /** *
     * get class hours
     */
    get_class_hours(date) {
      return (
        moment
          .utc(date)
          .local()
          .format('h:mm a') +
        ' ' +
        moment()
          .tz(moment.tz.guess())
          .format('z')
      );
    },
    /** *
     * get classes
     */
    async get_class(offset = 0) {
      this.loading = true;
      var params = new URLSearchParams();
      if (offset != 0) {
        // sort the request by date
        params.append('offset', offset);
      }
      // sort the request by date
      params.append('sort[0][field]', 'date');
      params.append('sort[0][direction]', 'asc');
      // get resp by dates
      if (this.selectedDate == moment().format('YYYY-MM-DD')) {
        params.append(
          'filterByFormula',
          'IF(AND(({date} >= "' +
            moment()
              .seconds(0)
              .milliseconds(0)
              .toISOString() +
            '"), ({date} < "' +
            moment(this.selectedDate)
              .add(1, 'days')
              .toISOString() +
            '")), 1, 0)',
        );
      } else {
        params.append(
          'filterByFormula',
          'IF(AND(({date} >= "' +
            moment(this.selectedDate).toISOString() +
            '"), ({date} < "' +
            moment(this.selectedDate)
              .add(1, 'days')
              .toISOString() +
            '")), 1, 0)',
        );
      }

      const api = ApiClassesGet();
      api.params = params;
      // Submit api
      const resp = await this.$callApi(api);
      if (resp != null) {
        this.classes = resp.data.records;
        this.loading = false;
      }
    },
    /** *
     * remove filter
     */
    remove_filter() {
      this.classType = 'all';
      this.minTime = 10;
      this.maxTime = 90;
      this.slider.noUiSlider.set([this.minTime, this.maxTime]);
      this.$router.push({ name: 'classes' });
      setTimeout(() => {
        this.isFiltered = false;
      }, 100);
    },
    /** *
     * show more modal
     */
    show_more_modal(item) {
      this.moreModalClass = item;
      this.$bvModal.show('showMore');
    },
  },
  /** *
   * created
   */
  created() {
    this.get_class();
  },
  mounted() {
    if (Object.keys(this.$route.query).length != 0 && this.$route.query.constructor === Object) {
      this.classType = this.$route.query.classType;
      this.isFiltered = true;
    }

    setTimeout(() => {
      this.slider = document.getElementById('sliderLength');
      noUiSlider.create(this.slider, {
        start: [10, 90],
        step: 5,
        connect: true,
        range: {
          min: 10,
          max: 90,
        },
      });

      this.slider.noUiSlider.on('update', (values, handle) => {
        this[handle ? 'maxTime' : 'minTime'] = parseInt(values[handle]);
      });
    }, 500);
  },
};
