import Vue from 'vue';
import {
  TOASTS_SET, TOASTS_CLOSE, TOASTS_LOADING, TOASTS_DECISION,
} from './mutation-types.js';

export default {
  /**
   * Sets the toast if the action has succeeded or not
   * @param {*} state Vue store state
   * @param {Object} data Oject conating information for api post calls
   */
  [TOASTS_SET](state, data) {
    state.timer = true;
    if (data.timer != null) Vue.set(state, 'timer', data.timer);
    setTimeout(() => {
      Vue.set(state, 'loading', false);
      Vue.set(state, 'error', data.error);
      Vue.set(state, 'message', data.message);
      Vue.set(state, 'sideMessage', data.sideMessage);
    }, 1000);

    if (state.timer) {
      setTimeout(() => {
        Vue.set(state, 'showToast', false);
      }, 3000);
    }
  },
  /**
   * Shows the toast after submittting something.
   * @param {*} state Vue store state
   */
  [TOASTS_LOADING](state) {
    Vue.set(state, 'showToast', true);
    Vue.set(state, 'loading', true);
  },
  /**
   * Closes the toast and resets everything
   * @param {*} state Vue store state
   */
  [TOASTS_CLOSE](state, data) {
    Vue.set(state, 'showToast', false);
    Vue.set(state, 'error', false);
    Vue.set(state, 'message', '');
    Vue.set(state, 'sideMessage', '');
    Vue.set(state, 'showButtons', false);
    Vue.set(state, 'timer', true);
  },
  /**
   * Make a decision when a button is pressed for either showing the data or not.
   * @param {*} state Vue store state
   */
  [TOASTS_DECISION](state, data) {
    Vue.set(state, 'toastDecision', data);
  },
};
