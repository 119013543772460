<template>
  <div class="main">
    <b-container>
      <b-row no-gutters class="justify-content-center align-items-center">
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Terms and Conditions</h2>
          <p>
            You are using a website operated by GANA. Our full company name is GANA. Referred to as "GANA" or "we" in
            this agreement. By accessing or using the GANA Website, the GANA Service, or any applications (including
            mobile applications) made available by GANA (together, the "Service"), however accessed, you agree to be
            bound by the following terms and conditions which consists of the following policies:
          </p>
          <ul>
            <li>
              <p>General Terms of Use</p>
            </li>
            <li>
              <p>Acceptable Use</p>
            </li>
            <li>
              <p><router-link to="/privacy">Privacy Policy</router-link></p>
            </li>
          </ul>
          <p>(and together, are the “Terms and Conditions”).</p>
          <p>
            The Service is owned or controlled by GANA. These Terms and Conditions affect your legal rights and
            obligations. If you do not agree to be bound by all of these Terms and Conditions, do not access or use the
            Service.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Contact GANA</h2>
          <p>
            If you have any questions regarding these Terms and Conditions, or if you have any questions, complaints,
            claims or other legal concerns relating to GANA or its business, please contact
            <a href="mailto:contact@getgana.com" title="send an email to contact@getgana.com">support@GANA.me</a>.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Definitions</h2>
          <ul>
            <li>
              <p>"Account": a registered user account with GANA</p>
            </li>
            <li>
              <p>"GANA" is the application owned and distributed by GANA</p>
            </li>
            <li>
              <p>
                "GANA Service": GANA provides the service of a platform where users can share content and ideas.
              </p>
            </li>
            <li>
              <p>
                "GANA Website": website https://www.GANA.me
              </p>
            </li>
            <li>
              <p>
                "Content": means any data, text, files, information, usernames, images, graphics, photos, profiles,
                links and other content or materials.
              </p>
            </li>
            <li>
              <p>
                "Product": means any digital service purchased through the GANA website.
              </p>
            </li>
            <li>
              <p>
                "Seller": means the instructor you are purchasing from.
              </p>
            </li>
            <li>
              <p>
                "User": means the person purchasing a subscription.
              </p>
            </li>
            <li>
              <p>
                "You" and "you": means anyone who uses the GANA Service.
              </p>
            </li>
            <li>
              <p>
                "Software": means the GANA application that a user can use on a computer or supported mobile device in
                order to utilize the Services; the Software may be accessed on the Internet.
              </p>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">General Terms of Use</h2>
          <ul>
            <li>
              <p>You must create an Account in order to use the GANA Service if you are a Seller.</p>
            </li>
            <li>
              <p>You must be at least 16 years old to use the Service.</p>
            </li>
            <li>
              <p>
                You must use the GANA website and GANA Service only in accordance with our Acceptable Use Policy.
              </p>
            </li>
            <li>
              <p>
                Seller is responsible for any activity that occurs through their Account and Seller agrees that the
                Seller will not sell, transfer, license or assign your account, followers, username, or any Account
                rights. GANA prohibits the creation of and Seller agrees that the Seller will not create an account for
                anyone other than yourself. Seller also represents that all information the Seller provides or provided
                to GANA upon registration and at all other times will be true, accurate, current and complete and you
                agree to update your information as necessary to maintain its truth and accuracy.
              </p>
            </li>
            <li>
              <p>
                You agree that you will not solicit, collect or use the login credentials of other GANA users.
              </p>
            </li>
            <li>
              <p>
                You are responsible for keeping your password secret and secure.
              </p>
            </li>
            <li>
              <p>
                Any links shared on GANA are your responsibility and we take no responsibility for any third party links
                shared on the GANA website. If we find that any third party links you have posted do not comply with our
                Terms and Conditions, you will be removed from the Service.
              </p>
            </li>
            <li>
              <p>
                You must not defame, stalk, bully, abuse, harass, threaten, impersonate or intimidate people or entities
                and you must not post private or confidential information via the Service. If found doing so we would
                delete your account and report you to the relevant authorities.
              </p>
            </li>
            <li>
              <p>
                You may not use the Service for any illegal or unauthorised purpose.
              </p>
            </li>
            <li>
              <p>
                You are solely responsible for your conduct and Content that you submit, post or display on or via the
                Service.
              </p>
            </li>
            <li>
              <p>
                You must not change, modify, adapt or alter the Service or change, modify or alter another website so as
                to falsely imply that it is associated with the Service or GANA
              </p>
            </li>
          </ul>
          <p>
            You must not submit unwanted email or other forms of commercial or harassing communications (a/k/a “spam”)
            to any GANA users.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Rights & Ownership</h2>
          <ul>
            <li>
              <p>
                GANA does not claim ownership of any Content that you post on or through the Service. Instead, you
                hereby grant to GANA a non-exclusive, fully paid and royalty-free, transferable, sub-licensable,
                worldwide license to use the Content that you post on or through the Service, subject to our Privacy
                Policy.
              </p>
            </li>
            <li>
              <p>
                You acknowledge that we may not always identify paid services, sponsored content, or commercial
                communications as such.
              </p>
            </li>
            <li>
              <p>
                You represent and warrant that: (i) you own the Content posted by you on or through the Service or
                otherwise have the right to grant the rights and licenses set forth in these Terms and Conditions; (ii)
                the posting and use of your Content on or through the Service does not violate, misappropriate or
                infringe on the rights of any third party, including, without limitation, privacy rights, publicity
                rights, copyrights, trademark and/or other intellectual property rights; (iii) you agree to pay for all
                royalties, fees, and any other monies owed by reason of Content you post on or through the Service; and
                (iv) you have the legal right and capacity to enter into these Terms and Conditions in your
                jurisdiction.
              </p>
            </li>
            <li>
              <p>
                You are responsible for any activity that occurs through your Account and you agree you will not sell,
                transfer, license or assign your account, followers, username, or any Account rights. With the exception
                of people or businesses that are expressly authorized to create Accounts on behalf of their employers or
                clients, GANA prohibits the creation of and you agree that you will not create an account for anyone
                other than yourself. You also represent that all information you provide or provided to GANA upon
                registration and at all other times will be true, accurate, current and complete and you agree to update
                your information as necessary to maintain its truth and accuracy.
              </p>
            </li>
            <li>
              <p>
                The Service contains Content owned or licensed by GANA ("GANA Content"). GANA Content is protected by
                copyright, trademark, patent, trade secret and other laws, and, as between you and GANA, GANA owns and
                retains all rights in the GANA Content and the Service. You will not remove, alter or conceal any
                copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying
                the GANA Content and you will not reproduce, modify, adapt, prepare derivative works based on, perform,
                display, publish, distribute, transmit, broadcast, sell, license or otherwise exploit the GANA Content.
              </p>
            </li>
            <li>
              <p>
                You agree that GANA is not responsible for, and does not endorse nor monitor, any third party Content
                posted within the Service. GANA does not have any obligation to pre-screen, monitor, edit, or remove any
                third party Content. If your Content violates these Terms and Conditions, you may bear legal
                responsibility for that Content.
              </p>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Website Payments</h2>
          <p>All web subscriptions are handled by Stripe and are bound by these terms and conditions.</p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Subscription Info</h2>
          <p>
            User subscribers to the payment plan set by the Seller. They are auto-renewing subscriptions and vary from
            one Seller to another.
          </p>
          <p>Subscribing will give you access to all services mentioned by the Seller.</p>
          <ul>
            <li>
              <p>
                If you decide to subscribe, you agree to instantly pay the price shown upon confirmation of purchase.
              </p>
            </li>
            <li>
              <p>
                If it is not cancelled earlier on, a subscription will automatically renew within 24 hours before the
                end of the current subscription period.
              </p>
            </li>
            <li>
              <p>
                Your account will be charged for renewal within 24 hours before the end of the current subscription
                period.
              </p>
            </li>
            <li>
              <p>
                The amount you paid for your subscription upon purchase is non-refundable even if the subscription is
                terminated early.
              </p>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Refund Policy</h2>
          <p>Please review our refund policy here <router-link to="/refund">Refund Policy</router-link></p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Acceptable Use Policy</h2>
          <p>
            This acceptable use policy sets out the terms between you and us under which you may access the Service.
            This acceptable use policy applies to all users of, and visitors to, the Service.
          </p>
          <p>
            Your use of our site means that you accept, and agree to abide by, all the policies in this acceptable use
            policy, which form part of our Terms and Conditions.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Prohibited uses</h2>
          <p>
            You may use our site only for lawful purposes. You may not use our site:
          </p>
          <ul>
            <li>
              <p>
                In any way that breaches any applicable local, national or international law or regulation.
              </p>
            </li>
            <li>
              <p>
                In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.
              </p>
            </li>
            <li>
              <p>
                For the purpose of harming or attempting to harm minors in any way.
              </p>
            </li>
            <li>
              <p>
                To send, knowingly receive, upload, download, use or re-use any material which does not comply with our
                content standards.
              </p>
            </li>
            <li>
              <p>
                To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional
                material or any other form of similar solicitation (spam).
              </p>
            </li>
            <li>
              <p>
                To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms,
                time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code
                designed to adversely affect the operation of any computer software or hardware.
              </p>
            </li>
          </ul>
          <p>You also agree:</p>
          <ul>
            <li>
              <p>
                Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions of
                our General Terms of Use.
              </p>
            </li>
            <li>
              <p>
                Not to access without authority, interfere with, damage or disrupt:
              </p>
            </li>
            <li>
              <p>
                any part of our site;
              </p>
            </li>
            <li>
              <p>
                any equipment or network on which our site is stored;
              </p>
            </li>
            <li>
              <p>
                any software used in the provision of our site; or
              </p>
            </li>
            <li>
              <p>
                any equipment or network or software owned or used by any third party.
              </p>
            </li>
            <li>
              <p>
                Not to abuse our staff through emails and social media. Any form of abuse will result in your account
                being blocked.
              </p>
            </li>
          </ul>
          <p>
            We will provide clear information to you about the kind of service offered, if it is moderated and what form
            of moderation is used (including whether it is human or technical).
          </p>
          <p>
            We will do our best to assess any possible risks for users (and in particular, for children) from third
            parties when they use any interactive service provided on our site, and we will decide in each case whether
            it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in
            the light of those risks. However, we are under no obligation to oversee, monitor or moderate any
            interactive service we provide on our site, and we expressly exclude our liability for any loss or damage
            arising from the use of any interactive service by a user in contravention of our content standards, whether
            the service is moderated or not.
          </p>
          <p>
            The use of any of the subscription services, provided by a Seller, by a minor is subject to the consent of
            their parent or guardian. We advise parents who permit their children to use GANA Service that it is
            important that they communicate with their children about their safety online, as moderation is not
            foolproof. Minors who are using GANA Service should be made aware of the potential risks to them.
          </p>
          <p>
            These content standards apply to any and all material which you contribute to our site (contributions), and
            to any interactive services associated with it.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Acceptable Use Policy</h2>
          <p>
            This acceptable use policy sets out the terms between you and us under which you may access the Service.
            This acceptable use policy applies to all users of, and visitors to, the Service.
          </p>
          <p>
            Your use of our site means that you accept, and agree to abide by, all the policies in this acceptable use
            policy, which form part of our Terms and Conditions.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Suspension and termination</h2>
          <p>
            We will determine, in our discretion, whether there has been a breach of this acceptable use policy through
            your use of our site. When a breach of this policy has occurred, we may take such action as we deem
            appropriate.
          </p>
          <p>
            Failure to comply with this acceptable use policy constitutes a material breach of our Terms and Conditions
            upon which you are permitted to use our site, and may result in our taking all or any of the following
            actions:
          </p>
          <ul>
            <li>
              <p>
                Immediate, temporary or permanent withdrawal of your right to use our site.
              </p>
            </li>
            <li>
              <p>
                Immediate, temporary or permanent removal of any posting or material uploaded by you to our site.
              </p>
            </li>
            <li>
              <p>
                Issue of a warning to you.
              </p>
            </li>
            <li>
              <p>
                Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not
                limited to, reasonable administrative and legal costs) resulting from the breach.
              </p>
            </li>
            <li>
              <p>
                Further legal action against you.
              </p>
            </li>
            <li>
              <p>
                Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.
              </p>
            </li>
          </ul>
          <p>
            We exclude liability for actions taken in response to breaches of this acceptable use policy. The responses
            described in this policy are not limited, and we may take any other action we reasonably deem appropriate.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Changes to the acceptable use policy</h2>
          <p>
            We may revise this acceptable use policy at any time by amending this page. You are expected to check this
            page from time to time to take notice of any changes we make, as they are legally binding on you. Some of
            the provisions contained in this acceptable use policy may also be superseded by provisions or notices
            published elsewhere on our site.
          </p>
        </b-col>
        <b-col cols="12" class="mb-4 mt-3">
          <h2 class="font-weight-bold">Contact GANA</h2>
          <p>
            If you have any questions regarding these Terms and Conditions, or if you have any questions, complaints,
            claims or other legal concerns relating to GANA or its business, please contact
            <a href="mailto:contact@getgana.com" title="send an email to contact@getgana.com">support@GANA.me</a>.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
