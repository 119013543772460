import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

const state = {
  // to show the toast
  showToast: false,
  // to check if the action failed/succeeded
  error: false,
  // Main message to display to the user
  message: '',
  // Extra information about the error
  sideMessage: null,
  // Loading is called when the action has started
  loading: false,
  // Timer to make the message go away
  timer: true,
  // decision
  toastDecision: null,
};

export default {
  state,
  actions,
  getters,
  mutations,
};
