import staticData from '@/static/index.json';
import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

const state = {
  class_type: staticData.classType,
};

export default {
  state,
  actions,
  getters,
  mutations,
};
