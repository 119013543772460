import Vue from 'vue';
import VueRouter from 'vue-router';
import Api from '@/utils/http.js';
import store from '@/store/index.js';
import classes from '@/components/classes/classes.vue';
import about_us from '@/components/about_us/about_us.vue';
// Privacy policy
import privacy_policy from '@/components/privacy_policy/privacy_policy.vue';
// Terms
import terms from '@/components/terms/terms.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /** *****************
     * classes
     ******************* */
    {
      path: '/',
      name: 'classes',
      component: classes,
      alias: '/classes',
    },
    /** *****************
     * about us
     ******************* */
    {
      path: '/about-us',
      name: 'about_us',
      component: about_us,
    },
    /** *****************
     * Policy
     ******************* */
    {
      path: '/privacy',
      name: 'privacy',
      component: privacy_policy,
      meta: {
        requiresAuth: false,
        auth: true,
      },
    },

    /** *****************
     * Terms
     ******************* */
    {
      path: '/terms',
      name: 'terms',
      component: terms,
      meta: {
        requiresAuth: false,
        auth: true,
      },
    },
  ],
});

export default router;
