/** ************************************************************
                        Class API
************************************************************* */
/** Check if user can use email */
export const ApiClassesGet = () => ({
  method: 'GET',
  url: 'https://api.airtable.com/v0/app2fKMWPTKv2yAOG/Classes',
  params: {},
  // ?filterByFormula=IF%28AND%28IS_AFTER%28%7Bdate%7D%2C+%272021-01-24T00%3A00%3A00%2B00%3A00%27%29%2C+IS_BEFORE%28%7Bdate%7D%2C+%272021-01-24T23%3A59%3A00%2B00%3A00%27%29%29%2C+1%2C+0%29&sort%5B0%5D%5Bfield%5D=date&sort%5B0%5D%5Bdirection%5D=asc',
});
