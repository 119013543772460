import store from '../store/index.js';
import router from '../router/index.js';

const errors = {
  // Unauthorized - 401 status: remove JWT token and redirect to login page
  // 401: (error) => {
  //   store.commit(AUTH_REMOVE_TOKEN);
  //   router.push({ name: 'login' });
  // },
};

export default errors;
