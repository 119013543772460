import axios from 'axios';
import store from '@/store/index.js';
import Errors from './error.js';

// Set api base URL here
// // Airtable
axios.defaults.baseURL = 'https://api.airtable.com/v0';

/*
 * Returns default headers list
 * which will be used with every request.
 */
function getHeaders() {
  const headers = {};

  headers.Authorization = 'Bearer keynDl4M0o0Wroldc';

  return headers;
}

/**
 * Handels general HTTP response error codes from Axios
 * Check utils/error.js for all supported error codes
 * @param {*} error Axios error object
 */
function handleError(error) {
  // console.log(error);
  const { status } = error.response;

  // Handles the error if a default handelr exists in Errors
  if (status in Errors) {
    Errors[status](error);
    return Promise.resolve();
  }
  // Forward the error
  return Promise.reject(error);
}

/**
 * Attaches URL route parameters to the given URL
 * @param {String} url Url for the API with route parameters
 * @param {Oject} params The route prametrs with their corresponding values
 */
function formatURL(url, params) {
  if (!params) return url;

  // Regex to match all route parmater placeholders
  const re = new RegExp('/:(.+?)(/|$)', 'g');

  // Throw Error if Insufficinet (or) excess parameters
  if ((url.match(re) || []).length !== Object.keys(params).length) {
    throw Error('Insufficinet (or) excess parameters while formating API URL');
  }

  // Replace all route parmater placeholders with their corresponding values
  return url.replace(re, (...p) => `/${params[p[1]]}${p[2]}`);
}

/**
 * A wrapper for axios() function which handles both, the API call and general http errors.
 * Check utils/error.js for all supported errors
 * @param {Object} config Axios configuration for the API
 * @param {Function} errorCallback Optional fallback function called instead of handleError when error is recieved
 * @returns {Promise} A
 */
function apiRequest(config, errorCallback) {
  const { url, urlParams, headers } = config;

  // Add route parameters to URL
  config.url = formatURL(url, urlParams);
  // Add addtional headers including JWT token
  config.headers = { ...getHeaders(), ...headers };

  // Logging for debuging
  // console.group('Axios Config');
  // console.log('This config was recieved by http.js:');
  // console.log(config);
  // console.groupEnd();

  return (
    axios(config)
      // Redirect error to handleError if fallback not provided
      .catch(errorCallback || handleError)
  );
}

/**
 * @deprecated In favor of apiRequest
 */
function old_apiRequest(config, callback, fallback) {
  const { url, urlParams, headers } = config;

  config.url = formatURL(url, urlParams);
  config.headers = { ...getHeaders(), ...headers };

  // console.log(config);

  return (
    axios(config)
      // Redirect response if callback not provided
      .then(callback || ((response) => response))
      // Redirect error to handleError if fallback not provided
      .catch(fallback || handleError)
  );
}

export default apiRequest;
