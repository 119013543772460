import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";

const state = {};

export default {
  state,
  actions,
  getters,
  mutations
};
