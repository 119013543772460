import { required, email } from 'vuelidate/lib/validators';

import { ApiSignUpPost } from '@//apiDocument/signup/index.js';

export default {
  name: '',
  data() {
    return this.initial_state();
  },
  /**
   * @NOTE: using Vuelidate for checking the validations.
   */
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    studentSelected() {
      if (this.isStudent) {
        return 'bg-white shadow';
      }
      return;
    },
    instructorSelected() {
      if (this.isInstructor) {
        return 'bg-white shadow';
      }
      return;
    },
  },
  components: {
    hiit: () => import('./svg/hiit.vue'),
    yoga: () => import('./svg/yoga.vue'),
    barre: () => import('./svg/barre.vue'),
    pilates: () => import('./svg/pilates.vue'),
    prenatal: () => import('./svg/prenatal.vue'),
    other: () => import('./svg/other.vue'),
  },
  methods: {
    initial_state() {
      return {
        isStudent: true,
        isInstructor: false,
        email: '',
        submitted: false,

        empty: false,
        errors: false,
      };
    },
    /** *
     *  send email
     */
    async send_email() {
      // Check if there were any errors in the form
      this.errors = this.$v.email.$anyError;
      // Check if the form was empty
      this.empty = !this.$v.email.$dirty;

      // If all checks are fine then submit
      if (!this.errors && !this.empty) {
        // Disable the submit button
        this.submitted = true;

        // Start loading animation
        this.$store.commit(this.$toastLoading);

        const api = ApiSignUpPost();
        api.data = { fields: { email: this.email, type: this.isStudent ? 'student' : 'instructor' } };

        // Submit api
        const resp = await this.$callApi(api);
        if (resp != null) {
          // If submit fails then let them login again
          this.$store.commit(this.$toastSet, {
            message: 'Thank you for signing up!',
            error: false,
          });
          this.email = '';
        }
      }
    },
    /** *
     * Then move to the login screen
     */
    leave(el, done) {
      el.style.position = 'absolute';
      done();
    },
  },
  mounted() {},
};
