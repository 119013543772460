import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueLazyload from 'vue-lazyload';
import Vuelidate from 'vuelidate';
import VueCookies from 'vue-cookies';
import VueSocialSharing from 'vue-social-sharing';
import PortalVue from 'portal-vue';
import VueAnalytics from 'vue-analytics';
import { TOASTS_SET, TOASTS_LOADING } from '@/store/modules/toasts/mutation-types.js';

import App from './App.vue';
import router from './router';
import store from './store';
import Api from './utils/http.js';

Vue.use(PortalVue);

// social sharing
Vue.use(VueSocialSharing);

// Import bootstrap vue
Vue.use(BootstrapVue);

// Global check form
Vue.use(Vuelidate);

// Global plugin registration
Vue.use(VueCookies);

// for google analytics
Vue.use(VueAnalytics, {
  id: 'UA-188235650-1',
  router,
});

// lazy load images
Vue.use(VueLazyload, {
  loading: 'https://storage.googleapis.com/fitdays_storage/static/loading.gif',
});

Vue.config.productionTip = false;

// Global properties registration
// // For calling api from store
Vue.prototype.$callApi = Api;
// // For calling toast mutation from store
Vue.prototype.$toastSet = TOASTS_SET;
Vue.prototype.$toastLoading = TOASTS_LOADING;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
